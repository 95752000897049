.news-posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:after {
    display: block;
    flex-grow: 2;
    content: '';
  }

  h2 {
    width: 100%;
  }
}

.news-post {
  position: relative;
  width: 100%;
  padding: 20px 20px 70px 20px;
  margin: 15px 10px;
  border: 1px solid #dedede;

@include medium-up {
    width: calc(25% - 20px);
  }
}

.news-post-image {
  width: calc(100% + 40px);
  margin: -20px -20px 20px;
  border-bottom: 2px solid #dedede;

  > img {
    width: 100%;
  }
}

.news-post-headline {
  margin: 0;
  line-height: 1.5rem;

  span {
    font-size: 24px;
  }
}

.property-detail {
  margin: 0 auto;
}

.property-listing-title {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  padding-bottom: 2px;
  border-bottom: 2px solid #00397c;

  &:hover {
    text-decoration: none;
  }
}

.property-detail-document-lists {
  display: block;
  width: 100%;
  padding: 30px 0;
  margin-top: 30px;
  clear: both;
  border-top: 2px solid #00397c;

  a {
    display: inline-block;
    padding: 15px;
    color: #fff;
    text-align: center;
    background: $primary-color;
    border: 2px solid $primary-color;
    border-radius: 10px;

    .fa-file {
      margin: 0 auto 20px auto;
      font-size: 50px;
    }

    &:visited {
      color: #fff;
    }

    &:hover {
      color: $primary-color;
      text-decoration: none;
      background: #fff;
      border: 2px solid $primary-color;
    }
  }
}

.news-post-blurb {
  p {
    font-size: 16px;
    line-height: 18px;
  }
}

hr {
  border: 0;
  border-top: 2px solid $primary-color;
}
