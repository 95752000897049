.banner-wrapper {
  position: relative;
  width: 100%;
  height: 250px;
  background: url('/themes/athena/assets/dist/images/subpage-banner.jpg') no-repeat;
  background-position: center;
  background-size: auto 100%;
}

.title {
  position: absolute;
  top: -150px;
  left: 50%;
  width: 100%;
  padding: 0 20px;
  margin: auto;
  transform: translateX(-50%);

@include medium-up {
    padding: 0;
  }

  h1,
  > span {
    font-size: 48px;
    font-weight: $bold;
    color: $subpage-banner-heading-color;

@include medium-up {
      width: 100%;
      max-width: 1600px;
      padding: 0 50px;
      margin: auto;
    }
  }
}
