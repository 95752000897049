img {
  display: block;
  height: auto;
  max-width: 100%;

@include medium-up {
    max-width: 100%;
  }  -ms-interpolation-mode: bicubic;
  &[class*='left'] {
    float: left;
    max-width: 40%;
    margin: 0 20px 15px 0;

@include medium-up {
      margin: 0 50px 20px 0;
    }
  }

  &[class*='right'] {
    float: right;
    max-width: 40%;
    margin: 0 0 15px 20px;

@include medium-up {
      margin: 0 0 20px 50px;
    }
  }

  &[class*='center'] {
    margin: 0 auto 15px auto;

@include medium-up {
      margin: 0 auto 20px auto;
    }
  }
}

figure {
  display: block;
  height: auto;
  max-width: 100%;

@include medium-up {
    max-width: 100%;
  }  -ms-interpolation-mode: bicubic;
  img {
    display: block;
    margin: 0 auto;
  }

  img[class*='align'] {
    float: none;
    margin: 0 auto;

@include medium-up {
      float: none;
      margin: 0 auto;
    }
  }

  &[class*='left'] {
    float: left;
    max-width: 40%;
    margin: 0 20px 15px 0;

@include medium-up {
      margin: 0 50px 20px 0;
    }
  }

  &[class*='right'] {
    float: right;
    max-width: 40%;
    margin: 0 0 15px 20px;

@include medium-up {
      margin: 0 0 20px 50px;
    }
  }

  &[class*='center'] {
    margin: 0 auto 15px auto;

@include medium-up {
      margin: 0 auto 20px auto;
    }
  }

  figcaption {
    padding: 10px 25px;
    margin-top: 1px;
    color: white;
    background: black;

@include fontsize(15 18);
  }
}

.image-bar {
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  img {
    height: 165px;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}
