/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: block;
}
.show-inline {
  display: inline;
}
.show-inline-block {
  display: inline-block;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 1000px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 999px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 1000px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 999px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 1000px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 1000px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.accordions {
  margin-bottom: 20px;
}
.accordions .accordion-title {
  position: relative;
}
.accordions .accordion-title button {
  position: relative;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 20px 82px 10px 0;
  text-align: left;
  color: black;
  border: 0;
  border-bottom: 2px solid gray;
  background: transparent;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.accordions .accordion-title button span {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 80px;
  height: calc(100% + 2px);
  border-bottom: 2px solid gray;
}
.accordions .accordion-title button span:after {
  position: absolute;
  top: 50%;
  left: 32px;
  content: "\f067";
  transform: translateY(-8px);
  text-align: center;
  text-decoration: inherit;
  color: black;
  font-family: FontAwesome;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
}
.accordions .accordion-title button:hover {
  cursor: pointer;
}
.accordions .accordion-title button:hover, .accordions .accordion-title button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title button:hover span, .accordions .accordion-title button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button, .accordions .accordion-title.is-active button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title.is-active button span, .accordions .accordion-title.is-active button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button span:after, .accordions .accordion-title.is-active button:focus span:after {
  content: "\f068";
}
.accordions .accordion-content[aria-hidden] {
  display: none;
}
.accordions .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active h2 {
  margin: 0;
}
.sidebar-layout {
  overflow-x: hidden;
}
.sidebar-layout main {
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
}
.sidebar-layout main .main-content {
  width: 100%;
  padding: 65px 20px 30px;
}
@media (min-width: 1000px) {
  .sidebar-layout main .main-content {
    width: 70%;
    padding: 100px 70px;
  }
}
.sidebar-layout main .sidebar-content {
  width: 100%;
  background: #ffffff;
  padding: 30px 20px;
}
@media (min-width: 1000px) {
  .sidebar-layout main .sidebar-content {
    padding: 100px 40px 100px 55px;
    width: 30%;
    position: relative;
  }
  .sidebar-layout main .sidebar-content:before {
    content: "";
    background: #ffffff;
    width: 100vw;
    height: 100%;
    position: absolute;
    display: inline-block;
    z-index: 0;
    top: 0;
  }
}
.sidebar-layout.left main .main-content {
  order: 1;
}
@media (min-width: 1000px) {
  .sidebar-layout.left main .main-content {
    order: 2;
  }
}
.sidebar-layout.left main .sidebar-content {
  order: 2;
}
.sidebar-layout.left main .sidebar-content:before {
  right: 100%;
}
@media (min-width: 1000px) {
  .sidebar-layout.left main .sidebar-content {
    order: 1;
  }
}
.sidebar-layout.right main .main-content {
  order: 1;
}
.sidebar-layout.right main .sidebar-content {
  order: 2;
}
.sidebar-layout.right main .sidebar-content:before {
  left: 100%;
}
.sidebar-layout.two .main-content {
  order: 1;
  width: 100%;
  padding: 65px 20px 30px;
}
@media (min-width: 1000px) {
  .sidebar-layout.two .main-content {
    order: 2;
    width: 50%;
    padding: 100px 70px;
  }
}
.sidebar-layout.two .sidebar-content {
  width: 100%;
  padding: 30px 20px;
}
@media (min-width: 1000px) {
  .sidebar-layout.two .sidebar-content {
    padding: 100px 40px 100px 55px;
    width: 25%;
  }
}
.sidebar-layout.two .sidebar-content.left {
  order: 2;
}
.sidebar-layout.two .sidebar-content.left:before {
  right: 100%;
}
@media (min-width: 1000px) {
  .sidebar-layout.two .sidebar-content.left {
    order: 1;
  }
}
.sidebar-layout.two .sidebar-content.right {
  order: 3;
}
.sidebar-layout.two .sidebar-content.right:before {
  left: 100%;
}
@media (min-width: 1000px) {
  .sidebar-layout.two .sidebar-content.right {
    order: 3;
  }
}

img {
  display: block;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
}
@media (min-width: 1000px) {
  img {
    max-width: 100%;
  }
}
img[class*=left] {
  float: left;
  max-width: 40%;
  margin: 0 20px 15px 0;
}
@media (min-width: 1000px) {
  img[class*=left] {
    margin: 0 50px 20px 0;
  }
}
img[class*=right] {
  float: right;
  max-width: 40%;
  margin: 0 0 15px 20px;
}
@media (min-width: 1000px) {
  img[class*=right] {
    margin: 0 0 20px 50px;
  }
}
img[class*=center] {
  margin: 0 auto 15px auto;
}
@media (min-width: 1000px) {
  img[class*=center] {
    margin: 0 auto 20px auto;
  }
}

figure {
  display: block;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
}
@media (min-width: 1000px) {
  figure {
    max-width: 100%;
  }
}
figure img {
  display: block;
  margin: 0 auto;
}
figure img[class*=align] {
  float: none;
  margin: 0 auto;
}
@media (min-width: 1000px) {
  figure img[class*=align] {
    float: none;
    margin: 0 auto;
  }
}
figure[class*=left] {
  float: left;
  max-width: 40%;
  margin: 0 20px 15px 0;
}
@media (min-width: 1000px) {
  figure[class*=left] {
    margin: 0 50px 20px 0;
  }
}
figure[class*=right] {
  float: right;
  max-width: 40%;
  margin: 0 0 15px 20px;
}
@media (min-width: 1000px) {
  figure[class*=right] {
    margin: 0 0 20px 50px;
  }
}
figure[class*=center] {
  margin: 0 auto 15px auto;
}
@media (min-width: 1000px) {
  figure[class*=center] {
    margin: 0 auto 20px auto;
  }
}
figure figcaption {
  padding: 10px 25px;
  margin-top: 1px;
  color: white;
  background: black;
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}

.image-bar {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.image-bar img {
  height: 165px;
  margin-right: 12px;
}
.image-bar img:last-child {
  margin-right: 0;
}

.subpage {
  position: relative;
  overflow-x: hidden;
}
.subpage main {
  padding: 65px 20px 80px;
  position: relative;
}
.subpage main h1 {
  margin-top: 0;
}
@media (min-width: 1000px) {
  .subpage main {
    padding: 100px 50px;
    max-width: 1600px;
    justify-content: center;
    margin: 0 auto;
  }
}

.banner-wrapper {
  position: relative;
  width: 100%;
  height: 250px;
  background: url("/themes/athena/assets/dist/images/subpage-banner.jpg") no-repeat;
  background-position: center;
  background-size: auto 100%;
}

.title {
  position: absolute;
  top: -150px;
  left: 50%;
  width: 100%;
  padding: 0 20px;
  margin: auto;
  transform: translateX(-50%);
}
@media (min-width: 1000px) {
  .title {
    padding: 0;
  }
}
.title h1,
.title > span {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
}
@media (min-width: 1000px) {
  .title h1,
.title > span {
    width: 100%;
    max-width: 1600px;
    padding: 0 50px;
    margin: auto;
  }
}

@media (min-width: 1000px) {
  table.responsive-table {
    border-radius: 5px;
    border-collapse: inherit;
    border-spacing: 0;
    overflow: hidden;
    width: 100%;
  }
}
table.responsive-table .cell-heading {
  display: inline-block;
  width: 30%;
  font-weight: 700;
}
@media (min-width: 1000px) {
  table.responsive-table .cell-heading {
    display: none;
  }
}
table.responsive-table .cell-content {
  display: inline-block;
  width: 70%;
}
@media (min-width: 1000px) {
  table.responsive-table .cell-content {
    display: block;
  }
}
table.responsive-table thead {
  background-color: #004996;
}
table.responsive-table thead tr th {
  color: #ffffff;
  text-transform: none;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
  border: 1px solid #004996;
  border-right-color: #fff;
  text-align: left;
}
@media (min-width: 1000px) {
  table.responsive-table thead tr th {
    padding: 20px;
    border: 2px solid #004996;
    border-right-color: #fff;
    border-bottom: 0;
  }
  table.responsive-table thead tr th:first-child {
    border-left: 2px solid #004996;
  }
  table.responsive-table thead tr th:nth-of-type(even) {
    border-left: 0;
  }
  table.responsive-table thead tr th:last-of-type {
    border-right: 2px solid #004996;
    border-right-color: #004996;
  }
}
table.responsive-table tbody tr:last-child {
  border-bottom: 0;
}
table.responsive-table tbody tr td {
  padding: 20px 0;
  border-bottom: 2px solid #d9d9d9;
  font-size: 16px;
}
@media (min-width: 1000px) {
  table.responsive-table tbody tr td {
    border-right: 2px solid #d9d9d9;
    padding: 20px;
  }
  table.responsive-table tbody tr td:first-child {
    border-left: 2px solid #d9d9d9;
  }
  table.responsive-table tbody tr td:nth-of-type(even) {
    border-left: 0;
  }
  table.responsive-table tbody tr td:last-of-type {
    border-right: 2px solid #d9d9d9;
  }
}

.tabs .tab-con {
  margin-bottom: 0;
  z-index: 1;
}
.tabs .tab-con .tab-list li:before {
  display: none;
}
.tabs .tab-con .tab-list li a {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #fff;
  border-left: 2px solid #d9d9d9;
  border-top: 2px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
  padding: 15px 35px;
  color: #00397C;
}
.tabs .tab-con .tab-list li a:before {
  content: "";
  background: #d9d9d9;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 2px;
  left: 0;
}
.tabs .tab-con .tab-list li a:hover, .tabs .tab-con .tab-list li a:focus, .tabs .tab-con .tab-list li a[aria-selected] {
  background: #00397C;
  color: white;
  border-color: #004996;
}
.tabs .tab-con .tab-list li a:hover:before, .tabs .tab-con .tab-list li a:focus:before, .tabs .tab-con .tab-list li a[aria-selected]:before {
  background: #004996;
}
.tabs .tab-con .tab-list li a:hover:hover, .tabs .tab-con .tab-list li a:hover:focus, .tabs .tab-con .tab-list li a:focus:hover, .tabs .tab-con .tab-list li a:focus:focus, .tabs .tab-con .tab-list li a[aria-selected]:hover, .tabs .tab-con .tab-list li a[aria-selected]:focus {
  background: #00397C;
  color: white;
}
.tabs .tab-con .tab-list li a:hover[role=tab]:focus, .tabs .tab-con .tab-list li a:focus[role=tab]:focus, .tabs .tab-con .tab-list li a[aria-selected][role=tab]:focus {
  background: #00397C;
  color: white;
}
.tabs .tab-content {
  background: white;
  padding: 25px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  z-index: 0;
}
.tabs .tab-content:before {
  content: "";
  background: #d9d9d9;
  position: absolute;
  width: 100%;
  top: -2px;
  height: 2px;
  left: 0;
}
@media (min-width: 1000px) {
  .tabs .tab-content {
    padding: 55px 0 25px;
  }
}

.accordions h2.accordion-title button {
  font-size: 20px;
  font-weight: 700;
  padding: 20px 0 10px 40px;
  line-height: 24px;
  color: #00397c;
  border: 0;
}
.accordions h2.accordion-title button:after {
  content: "\f055";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  left: 0;
  font-weight: 700;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  color: #004996;
}
.accordions h2.accordion-title button:hover, .accordions h2.accordion-title button:focus {
  color: #555768;
  border: 0;
}
.accordions h2.accordion-title button:hover:after, .accordions h2.accordion-title button:focus:after {
  color: #808185;
}
.accordions h2.accordion-title.is-active button {
  border: 0;
  color: #00397c !important;
}
.accordions h2.accordion-title.is-active button:after {
  content: "\f056";
  color: #004996;
}
.accordions h2.accordion-title.is-active button:focus {
  color: #00397c;
  border: 0;
}

.news-posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.news-posts:after {
  display: block;
  flex-grow: 2;
  content: "";
}
.news-posts h2 {
  width: 100%;
}

.news-post {
  position: relative;
  width: 100%;
  padding: 20px 20px 70px 20px;
  margin: 15px 10px;
  border: 1px solid #dedede;
}
@media (min-width: 1000px) {
  .news-post {
    width: calc(25% - 20px);
  }
}

.news-post-image {
  width: calc(100% + 40px);
  margin: -20px -20px 20px;
  border-bottom: 2px solid #dedede;
}
.news-post-image > img {
  width: 100%;
}

.news-post-headline {
  margin: 0;
  line-height: 1.5rem;
}
.news-post-headline span {
  font-size: 24px;
}

.property-detail {
  margin: 0 auto;
}

.property-listing-title {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  padding-bottom: 2px;
  border-bottom: 2px solid #00397c;
}
.property-listing-title:hover {
  text-decoration: none;
}

.property-detail-document-lists {
  display: block;
  width: 100%;
  padding: 30px 0;
  margin-top: 30px;
  clear: both;
  border-top: 2px solid #00397c;
}
.property-detail-document-lists a {
  display: inline-block;
  padding: 15px;
  color: #fff;
  text-align: center;
  background: #00397C;
  border: 2px solid #00397C;
  border-radius: 10px;
}
.property-detail-document-lists a .fa-file {
  margin: 0 auto 20px auto;
  font-size: 50px;
}
.property-detail-document-lists a:visited {
  color: #fff;
}
.property-detail-document-lists a:hover {
  color: #00397C;
  text-decoration: none;
  background: #fff;
  border: 2px solid #00397C;
}

.news-post-blurb p {
  font-size: 16px;
  line-height: 18px;
}

hr {
  border: 0;
  border-top: 2px solid #00397C;
}

.property-detail h1 {
  font-size: 30px;
  font-style: 400;
  font-weight: 700;
  font-weight: 700;
  color: #57585B;
}

.property-detail-image-lists {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 1000px) {
  .property-detail-image-lists {
    float: left;
    width: 50%;
    margin-right: 40px;
  }
  .property-detail-image-lists:after {
    position: absolute;
    top: 0;
    right: -40px;
    width: 40px;
    height: 100%;
    background: white;
    content: "";
  }
}
.property-detail-image-lists img {
  width: 10%;
  margin-right: 20px;
  cursor: pointer;
  object-fit: contain;
}
.property-detail-image-lists img:first-child {
  width: 100%;
  height: 500px;
  padding: 10px;
  margin: 0 0 20px 0;
  cursor: arrow;
  background: #f2f2f2;
  border: 1px solid #dedede;
  border-radius: 5px;
}

.property-detail-name,
.property-detail-copy {
  width: 100%;
  margin: 0 0 30px;
}
@media (min-width: 1000px) {
  .property-detail-name,
.property-detail-copy {
    display: inline-block;
    width: calc(50% - 40px);
  }
}

.property-detail-copy hr {
  margin-top: 50px;
  clear: both;
}
.property-detail-copy ul {
  padding-left: 40px;
}