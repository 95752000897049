.tabs {
  .tab-con {
    margin-bottom: 0;
    z-index: 1;

    .tab-list {
      li {
        &:before {
          display: none;
        }

        a {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          background: $tabs-inactive;
          border-left: 2px solid $tabs-border;
          border-top: 2px solid $tabs-border;
          border-right: 2px solid $tabs-border;
          padding: 15px 35px;
          color: $tabs-text;

          &:before {
            content: '';
            background: $tabs-border;
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 2px;
            left: 0;
          }

          &:hover,
          &:focus,
          &[aria-selected] {
            background: $primary-color;
            color: white;
            border-color: $tabs-active-border;

            &:before {
              background: $tabs-active-border;
            }

            &:hover,
            &:focus {
              background: $primary-color;
              color: white;
            }

            &[role="tab"] {
              &:focus {
                background: $primary-color;
                color: white;
              }
            }
          }
        }
      }
    }
  }

  .tab-content {
    background: white;
    padding: 25px 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
    z-index: 0;

    &:before {
      content: '';
      background: #d9d9d9;
      position: absolute;
      width: 100%;
      top: -2px;
      height: 2px;
      left: 0;
    }

    @include medium-up {
      padding: 55px 0 25px;
    }
  }
}