.property-detail {
  h1 {
    font-size: 30px;
    font-style: $regular;
    font-weight: 700;
    font-weight: $bold;
    color: $heading-text-dark;
  }
}

.property-detail-image-lists {
  position: relative;
  display: flex;

// overflow: hidden;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;

@include medium-up {
    float: left;
    width: 50%;
    margin-right: 40px;

    &:after {
      position: absolute;
      top: 0;
      right: -40px;
      width: 40px;
      height: 100%;
      background: white;
      content: '';
    }
  }
  img {
    width: 10%;
    margin-right: 20px;
    cursor: pointer;

    object-fit: contain;

    &:first-child {
      width: 100%;
      height: 500px;
      padding: 10px;
      margin: 0 0 20px 0;
      cursor: arrow;
      background: #f2f2f2;
      border: 1px solid #dedede;
      border-radius: 5px;
    }
  }
}

.property-detail-name,
.property-detail-copy {
  width: 100%;
  margin: 0 0 30px;
@include medium-up {
    display: inline-block;
    width: calc(50% - 40px);
  }
}

.property-detail-copy {
  hr {
    margin-top: 50px;
    clear: both;
  }

  ul {
    padding-left: 40px;
  }
}

